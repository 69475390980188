import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  HELP_AND_SUPPORT,
  HELP_DESK_MANAGER,
  HR_CONTROLLER,
  HR_MANAGER,
  HR_STAFF,
  ONBOARDING_USER,
  STAFF,
  SUPER_USER,
} from 'utilities/constants';

const useGetEmployeeRoles = () => {
  const { user } = useSelector(state => state.auth);

  const group = user?.groups[0]?.name;
  const isTeamHead = user?.is_team_head;

  const isSuperUser = group === SUPER_USER;
  const isHRController = group === HR_CONTROLLER;
  const isHRManager = group === HR_MANAGER;
  const isEmployee = group === HR_STAFF || group === STAFF;
  const isStaff = group === STAFF;
  const isHRStaff = group === HR_STAFF;
  const userProfileId = user?.profile?.id;
  const userId = user?.id;
  const isOnboardingUser = group === ONBOARDING_USER;
  const isCustomerCare = user?.profile?.department === HELP_AND_SUPPORT;
  const isCustomerCareStaff = user?.profile?.department === HELP_AND_SUPPORT && group === STAFF;
  const department = user?.profile?.department;
  const isHelpDeskManager = group === HELP_DESK_MANAGER;

  const allowInterviews = useMemo(() => {
    if (isSuperUser || isHRController || isHRManager) {
      return true;
    }

    if (user?.profile?.allow_interviews) {
      return true;
    }

    return false;
  }, [user, isSuperUser, isHRController, isHRManager]);

  return {
    group,
    isTeamHead,
    isSuperUser,
    isHRController,
    isHRManager,
    isEmployee,
    isStaff,
    isHRStaff,
    userProfileId,
    userId,
    allowInterviews,
    isOnboardingUser,
    isCustomerCare,
    department,
    isHelpDeskManager,
    isCustomerCareStaff,
  };
};

export default useGetEmployeeRoles;
