// import moment from 'moment';
import { yupLessTimeValidator, yupTimeValidator } from 'shared/helpers/utilityFunctions';
import * as yup from 'yup';

// function isTestEndDate(value) {
//   const { start_break_time: startBreakTime } = this.parent;
//   return moment(startBreakTime, 'HH:mm:ss').isSameOrBefore(moment(value, 'HH:mm:ss'));
// }

export const FACTS_FILTER_INIT_VALS = {
  employee__employee_name: '',
  start_date: '',
  end_date: '',
};

export const FACTS_FILTER_SCHEMA = yup.object({
  employee__employee_name: yup.string(),
  start_date: yup.date().typeError('Please enter a valid date'),
  end_date: yup
    .date()
    .typeError('Please enter a valid date')
    .min(yup.ref('start_date'), 'End date must be later than start date'),
});

export const checkOutFormInitialValue = {
  date: '',
  check_out_time: '',
  forget_to_check_out_reason: '',
};

export const checkOutFormInitialSchema = yup.object({
  date: yup.date().required('Required!').typeError('Invalid Date'),
  forget_to_check_out_reason: yup.string().required('Required!'),
  check_out_time: yup
    .string()
    .required('Required!')
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .typeError('Invalid Time'),
});

export const checkInFormInitialValue = {
  check_in_time: '',
  forget_to_check_in_reason: '',
  forget_to_check_in: false,
};

export const checkInFormInitialSchema = yup.object({
  check_in_time: yup
    .string()
    .required('Required!')
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .test('test-less-time', 'Invalid time!', yupLessTimeValidator)
    .typeError('Invalid Time'),
  forget_to_check_in_reason: yup.string().max(255, 'Maximum 255 characters allowed').required('Required!'),
});

export const editBreakFormInitialValue = {
  start_break_time: '',
  end_break_time: '',
  break_reason: '',
};

export const editBreakFormInitialSchema = yup.object({
  start_break_time: yup.string().required('Required!'),
  end_break_time: yup.string().test('test-time', 'Invalid time!', yupTimeValidator).required('Required!'),
  break_reason: yup.string().required('Required!'),
  // .test('end_break_time', 'End time must be after start time', isTestEndDate)
});

export const deleteAttendanceFormInitialValue = {
  reason: '',
};

export const deleteAttendanceFormInitialSchema = yup.object({
  reason: yup.string().max(255, 'Maximum 255 characters allowed').required('Required!'),
});
